import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState: any = {}

const bookingDetailSlice = createSlice({
  name: 'bookingDetail',
  initialState,
  reducers: {
    resetBookingDetail: () => initialState,
    updateBookingDetail: (state, action: PayloadAction<any>) => ({ ...state, ...action.payload }),
    updateLocationSuccess: (state, action: PayloadAction<any>) => {
      const { locationId, payload } = action.payload
      const currentLocation = state.locations.find((location:any) => (location.id === locationId))
      currentLocation.description = payload
    },
    updateVendorTippingSetting: (state, action: PayloadAction<any>) => {
      state.vendorTippingSetting = action.payload
    },
    updateVendorTippingAmount: (state, action: PayloadAction<any>) => {
      state.vendorTippingAmount = action.payload
    }
  },
  extraReducers: () => {},
})

export const bookingDetailCreator = bookingDetailSlice.actions

export default bookingDetailSlice
