import React from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

export interface WithRouterProps {
  router: {
    location: ReturnType<typeof useLocation>;
    params: Record<string, string>;
    navigate: ReturnType<typeof useNavigate>;
  }
}

const withRouter = <Props extends WithRouterProps>(
  Component: React.ComponentType<Props>
) => (
  props: any
) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  return <Component {...(props as Props)} router={{ location, navigate, params }} />
}

export default withRouter