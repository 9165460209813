import React, { useEffect } from 'react'
import $ from 'jquery'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import commonUtils from 'utils/common'
import * as locationActionCreators from 'store/actions/new_booking/locationActionCreators'
import * as stepActionCreators from 'store/actions/new_booking/stepActionCreators'

import { POPUP_ID_OUT_OF_SERVICE } from 'constants/common/popupConstants'
import { V2_ICON_GREEN, V2_ICON_GREEN_TRANSPORTIFY, EXTEND_MAP_ICON } from 'constants/imageConstants'
import { getCurentCompanyTypeId } from 'utils/booking/common'
import store, { useAppSelector, useAppDispatch } from 'store/store'

interface IPopupContent {
  link_url: string
  content: string
  link_text: string
  item_type: string
  icon_url: string
}

const loadModifiedTitle = (title: string) => {
  return (
    <h5 className="Normal-Booking-Title">
      {title}
    </h5>
  )
}

const contentElement = (item: IPopupContent, key: number) => {
  const url = item.link_url
  let content = item.content
  if (!_.isUndefined(url) && url.length > 0) {
    const linkText = item.link_text
    if (!_.isUndefined(linkText) && linkText.length > 0 &&
      content.indexOf(linkText) !== -1 &&
      _.trim(content).length >= _.trim(linkText).length) {
      const subContent = "<a href='" + url + "' class='modified-popup-url'>" + linkText + "</a>"
      content = content.split(linkText).join(subContent)
    }
  }
  return (
    <div key={key}>
      <p className="center pre-line" dangerouslySetInnerHTML={{ __html: content }}></p>
    </div>
  )
}

const contentSmallElement = (item: IPopupContent, key: number) => {
  const url = item.link_url
  let content = item.content
  if (!_.isUndefined(url) && url.length > 0) {
    const linkText = item.link_text
    if (!_.isUndefined(linkText) && linkText.length > 0 &&
      content.indexOf(linkText) !== -1 &&
      _.trim(content).length >= _.trim(linkText).length) {
      const subContent = `<a class='hyperlink-default-color' href='${url}' target='_blank'>${linkText}</a>`
      content = content.split(linkText).join(subContent)
    }
  }
  return (
    <div key={key}>
      <p className="center yellow fullday-custom" dangerouslySetInnerHTML={{ __html: content }}></p>
    </div>
  )
}

function OutServiceAreaPopup () {
  const currentCustomer = useAppSelector((s) => s.currentCustomer)
  const outOfServiceStatus = useAppSelector((s) => s.outOfServiceStatus)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const escFunction = (event: { keyCode: number }) => {
      if (event.keyCode === 27) {
        onS1CancelHandler()
      }
    }
    document.addEventListener('keydown', escFunction, false)
    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [])

  const onS1CancelHandler = () => {
    $(`#${POPUP_ID_OUT_OF_SERVICE}`).removeClass("visible")
    stepActionCreators.toggleDraggableMarkers(true)(dispatch, store.getState)
  }

  const onS1ContinueHandler = () => {
    $(`#${POPUP_ID_OUT_OF_SERVICE}`).removeClass('visible')
    const companyTypeId = getCurentCompanyTypeId(currentCustomer)
    if (companyTypeId) {
      locationActionCreators.updateCODPODFees()(dispatch, store.getState)
    }
    stepActionCreators.nextStep(navigate)(dispatch, store.getState)
  }


  const modifiedPopup = (popupContent: IPopupContent[], iconDefault: string) => {
    const icon = popupContent[0]["icon_url"] ? popupContent[0]["icon_url"] : iconDefault
    let title
    const itemContent:any = []
    const itemContentSmall:any = []
    popupContent.forEach((item: IPopupContent) => {
      if (item.item_type === 'ar_oos_title' || item.item_type === 'vt_oos_title') {
        title = loadModifiedTitle(item.content)
      } else if (item.item_type === 'ar_oos_content_small' || item.item_type === 'vt_oos_content_small') {
        itemContentSmall.push(item)
      } else if (!_.isUndefined(item.item_type) && (item.item_type.indexOf('ar_oos_content') !== -1 || item.item_type.indexOf('vt_oos_content') !== -1) || item.item_type === 'ar_cts_content') {
        itemContent.push(item)
      }
    })
    const contents = itemContent.map((value: IPopupContent, key: number) => {
      return contentElement(value, key)
    })
    const contentSmalls = itemContentSmall.map((value: IPopupContent, key: number) => {
      return contentSmallElement(value, key)
    })
    return (
      <div className="Box Modified-Popup">
        <div className="Box-Icon">
          <img src={icon} alt="icon" className="icon-modified-style" />
        </div>
        <div className="Box-Content">
          <div className="Normal">
            {title}
            {contents}
            {contentSmalls}
          </div>
        </div>
      </div>
    )
  }

  if (_.isEmpty(outOfServiceStatus) || outOfServiceStatus.error) return (<span />)
  const isSurcharged = !!outOfServiceStatus.out_of_service_area?.is_surcharged
  if (outOfServiceStatus.is_inside && !isSurcharged) return (<span />)

  let icon = commonUtils.isTransportify() ? V2_ICON_GREEN_TRANSPORTIFY : V2_ICON_GREEN
  let className = "visible vertical-scroll Modal modal-outOfService Modal-No-action"
  if (isSurcharged) {
    icon = EXTEND_MAP_ICON
    className = "visible vertical-scroll Modal modal-outOfService"
  }
  const popupContent = outOfServiceStatus.oos_popup_content
  const isOSA = popupContent && popupContent[1].item_type === 'ar_oos_title'
  return (
    <div id={POPUP_ID_OUT_OF_SERVICE} className={className}>
      <div className={`Normal-Booking ${isOSA ? 'Normal-Booking-prefix' : ''} z-index-2`}>
        {modifiedPopup(popupContent, icon)}
        {isSurcharged &&
          (
            <div className="Normal-Booking-Actions Custom">
              <a
                className={`Gray no-hover ${isOSA ? 'dlvr-button-prefix' : ''}`}
                onClick={onS1CancelHandler}
              >
                {t('webapp.new_booking.back')}
              </a>
              <a
                className={`White no-hover ${isOSA ? 'dlvr-button-prefix' : ''}`}
                onClick={onS1ContinueHandler}
              >
                {t('webapp.action.proceed')}
              </a>
            </div>
          )}
      </div>
      <div className="close-modal-common" onClick={() => onS1CancelHandler()} />
    </div>
  )
}

export default OutServiceAreaPopup
