import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import I18n from 'i18n/i18n'
import Modal from './Modal'
import { updateCurrentPopupID } from 'store/actions/common/currentPopupIDActionCreators'
import { nextStep } from 'store/actions/new_booking/stepActionCreators'

export default function SubmitEditsModal() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const extraInfo = useSelector(state => state.extraInfos)
  const reviewChangeTimeMinutes = Math.round(extraInfo.ceb_waiting_accept_timer / 60)

  const handleBack = () => {
    dispatch(updateCurrentPopupID(''))
  }

  const handleSubmit = () => {
    dispatch(nextStep(navigate))
    dispatch(updateCurrentPopupID(''))
  }

  return (
    <Modal className="booking-detail-modal submit-edits">
      <div className="modal-layout">
        <h3 className="title">
          {I18n.t('webapp.devina.popup.submit_edits')}
        </h3>
        <div className="modal-inner">
          <p
            className="modal-text"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: I18n.t('webapp.devina.popup.submit_edits_content', {
                time: `${reviewChangeTimeMinutes} ${reviewChangeTimeMinutes === 1
                  ? I18n.t('webapp.tally.unit.full_text_minute') : I18n.t('webapp.tally.unit.full_text_minutes')}`,
              })
            }}
          />
          <div className="btn-wrapper">
            <button type="button" className="back-btn" onClick={handleBack}>
              {I18n.t('webapp.action.back')}
            </button>
            <button type="button" className="main-btn" onClick={handleSubmit}>
              {I18n.t('closures.rate.submit')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
