import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  assignDriverBooking: {},
  cancelable: {},
  devinaStage: {},
  vehicleType: {},
  isShowMaskLoading: false,
  locatingDuration: 0,
  csFindingDriverTimeoutAt: 0,
  customerTimeoutAt: 0,
}

const devinaSlice = createSlice({
  name: 'devina',
  initialState,
  reducers: {
    resetDevina: () => initialState,
    updateAssignDriverBooking: (state, action: PayloadAction<any>) => {
      state.assignDriverBooking = action.payload
    },
    updateCancelable: (state, action: PayloadAction<any>) => {
      state.cancelable= action.payload
    },
    updateDevinaStage: (state, action: PayloadAction<any>) => {
      state.devinaStage= action.payload
    },
    updateVehicleType: (state, action: PayloadAction<any>) => {
      state.vehicleType= action.payload
    },
    updateMaskLoading: (state, action: PayloadAction<any>) => {
      state.isShowMaskLoading= action.payload
    },
    updateLocatingDuration: (state, action: PayloadAction<any>) => ({
      ...state,
      ...action.payload
    }),
    updateCsFindingTimeout: (state, action: PayloadAction<any>) => {
      state.csFindingDriverTimeoutAt= action.payload
    },
  },
  extraReducers: () => {},
})

export const devinaActionsCreator = devinaSlice.actions

export default devinaSlice