import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import CustomerAPI from '../../api/customers'
import {
  LIVE_CHAT,
  WALLET_ICON,
  FINAL_LEO_GRAPHIC,
  BACK_TO_TOP_ICON,
} from '../../constants/imageConstants'
import { Utils } from '../../utils/Utils'
import BookingCard from 'components/common/BookingCard'
import Card from 'components/common/Card'
import CommonUtils from 'utils/common'
import './frontPageStyle.scss'

const checkShowWallet = (currentCustomer, extraInfos) => {
  const isBusiness = !!currentCustomer?.last_login_employ_id

  return (
    currentCustomer.id &&
    currentCustomer?.credit?.amount > 0 &&
    ((isBusiness && extraInfos?.enabled_ewallet?.includes('business')) ||
      (!isBusiness && extraInfos?.enabled_ewallet?.includes('individual')))
  )
}

const checkScrollToVisible = (scrolled, callback) => {
  if (scrolled > 50) {
    callback(true)
  } else if (scrolled <= 50) {
    callback(false)
  }
}

const redirectWallet = (isBP, countryCode, navigate) => {
  const path = isBP
    ? `/business/credit_balances?${encodeURI(`search[country_code]=${countryCode}`)}`
    : `/credit_balances?${encodeURI(`search[country_code]=${countryCode}`)}`
  navigate(path)
}

const openChat = () => {
  if (!window.fcWidget.isOpen()) window.fcWidget.open()
}

const handleChooseService = async ({ service, areaId, companyId, navigate }) => {
  try {
    await CommonUtils.moengageTrackEvent(service.moe_event)
  } finally {
    if(service.data_type === "LCL") {
      const param = Utils.buildParamToFTL({
        countryCode: 'id',
        areaId: areaId,
        companyId,
      })
      window.location.replace(`${service.url}?${param}`)
    } else {
      const target = service.target || '_self'
      if (!service.url.includes('http') && target === '_self') { 
        navigate(service.url)
      } else {
        window.open(service.url, target)
      }
    }
  }
}

const renderItemMenu = ({ service, index, extraInfos, companyId, navigate }) => (
  <div
    key={index}
    className={`${extraInfos.turn_on_lcl_on_front_page ? 'cur-pointer' : ''} ${
      service.image_position === 'top' ? 'img-top' : 'img-bottom'
    }`}
    onClick={() => handleChooseService({ service, areaId: extraInfos?.area_id, companyId, navigate })}
  >
    {service.image_position === 'top' ? (
      <>
        <img src={service.image} alt="" />
        <div>
          <p className="front-page__title m0">{service.title}</p>
          <p className="front-page__content">{service.content}</p>
        </div>
      </>
    ) : (
      <>
        <div>
          <p className="front-page__title m0">{service.title}</p>
          <p className="front-page__content">{service.content}</p>
        </div>
        <img src={service.image} alt="" />
      </>
    )}
  </div>
)

const renderBooking = (listBooking, countryCode, t, navigate) => {
  if (!listBooking.length) return <></>
  return (
    <>
      <div className="front-page__list-booking">
        <p className="booking-card__title front-page__title m0">{t('lcl.recent_bookings')}</p>
        <button
          className="btn-see-all"
          onClick={() => {
            CommonUtils.moengageTrackEvent('Select See All On Recent Bookings Front Page')
            navigate('http://localhost:3000/bookings')
          }}
          type="button"
        >
          <span>{t('lcl.see_all')}</span>
        </button>
      </div>
      <div className="booking-card-wrapper">
        {!!listBooking.length &&
          listBooking.map((item, index) => (
            <BookingCard
              specialClass={index === listBooking.length - 1 && 'mr0'}
              countryCode={countryCode}
              key={index}
              booking={item}
            />
          ))}
      </div>
    </>
  )
}

const getBooking = async (currentCustomer, callback) => {
  const {
    data: { data },
  } = await CustomerAPI.getFrontPageBooking({
    from_time: '',
    to_time: '',
    sort_dir: 'desc',
    page: 1,
    per_page: 3,
    'company_ids[]': currentCustomer?.last_login_employ_id || 0,
  })
  callback(data || [])
}

const FrontPage = ({ frontPageMenu }) => {
  const [listBooking, setListBooking] = useState([])
  const frontPageRef = useRef()
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentCustomer, extraInfos }  = useSelector(({ currentCustomer, extraInfos }) => ({
    currentCustomer,
    extraInfos,
  }))

  const countryCode = extraInfos?.country_code || currentCustomer.country_code
  const liveChatContent = countryCode !== 'th' ? t('lcl.chat24h') : t('lcl.chatTh')
  const isShowWallet = checkShowWallet(currentCustomer, extraInfos)
  const isShowBatch = extraInfos?.enable_batch_multiple
  const isShowSmartLoad = extraInfos?.enable_smart_load
  const isShowAppExclusive = currentCustomer.id && (isShowBatch || isShowSmartLoad)

  const companyId = currentCustomer?.current_company_id
  const areaId = extraInfos?.area_id

  const backToTop = () => {
    frontPageRef.current.scrollTo({
      behavior: 'smooth',
      top: 0,
    })
  }

  useEffect(() => {
    if (currentCustomer.authentication_token) {
      getBooking(currentCustomer, (data) => {
        setListBooking(data)
      })
    }

    const frontPageEl = frontPageRef.current
    const toggleVisible = () => {
      checkScrollToVisible(frontPageEl.scrollTop, (isVisible) => {
        setVisible(isVisible)
      })
    }
    frontPageEl.addEventListener('scroll', toggleVisible)
    return () => {
      frontPageEl.removeEventListener('scroll', toggleVisible)
    }
  }, [])

  return (
    <div className="frontpage-floating">
      <div ref={frontPageRef} id="front-page-wrapper" className="front-page-wrapper">
        {frontPageMenu?.main_services?.show && (
          <div className="feature-project">
            <div className="front-page__title mb10">{t('lcl.main_services')}</div>
            {frontPageMenu?.main_services?.body?.map((item, index) => (
              <Card
                key={index}
                onClick={() => handleChooseService({ service: item, areaId, companyId, navigate })}
                className="cur-pointer"
                classContent="front-page__content"
                label={item.label}
                image={item.image}
                title={item.title}
                content={item.content}
              />
            ))}
          </div>
        )}
        {renderBooking(listBooking, countryCode, t, navigate)}
        <div className="mt30">
          <div className="flex front-page__support">
            {isShowWallet ? (
              <>
                <div className="mr20">
                  <div>
                    <img src={WALLET_ICON} alt="wallet" />
                    <p className="front-page__title">
                      {Utils.formatFee(
                        currentCustomer?.credit?.amount,
                        currentCustomer.country_code,
                        currentCustomer?.credit?.currency
                      )}
                    </p>
                  </div>
                  <p className="front-page__content">{t('lcl.credits_available')}</p>
                  <button
                    onClick={() => {
                      CommonUtils.moengageTrackEvent('Select Wallet On Front Page')
                      redirectWallet(!!currentCustomer?.last_login_employ_id, countryCode, navigate)
                    }}
                    type="button"
                  >
                    <span>{t('business.credit_balances.index.wallet')}</span>
                  </button>
                </div>
                <div>
                  <div>
                    <img src={LIVE_CHAT} alt="" />
                    <p className="front-page__title">{t('lcl.customer_support')}</p>
                  </div>
                  <p className="front-page__content">{liveChatContent}</p>
                  <button
                    onClick={() => {
                      CommonUtils.moengageTrackEvent('Select CS Chat On Front Page')
                      openChat()
                    }}
                    type="button"
                  >
                    <span>{t('lcl.chat_now')}</span>
                  </button>
                </div>
              </>
            ) : (
              <div className="front-page__full">
                <div>
                  <div>
                    <img src={LIVE_CHAT} alt="" />
                    <p className="front-page__title">{t('lcl.customer_support')}</p>
                  </div>
                  <p className="front-page__content">{liveChatContent}</p>
                </div>
                <button
                  onClick={openChat}
                  type="button"
                >
                  <span>{t('lcl.chat_now')}</span>
                </button>
              </div>
            )}
          </div>
          {isShowAppExclusive && (
            <div className="front-page__create" style={{ backgroundImage: `url(${FINAL_LEO_GRAPHIC})` }}>
              <p className="front-page__title mb5">{t('lcl.exclusive')}</p>
              <p className="front-page__content m0">{t('lcl.manage_multiple')}</p>
              <div>
                {isShowBatch && (
                  <>
                    <button
                      className="batch"
                      onClick={() => {
                        CommonUtils.moengageTrackEvent('Select Multiple On Front Page')
                        navigate('/bookings/multiple')
                      }}
                      type="button"
                    >
                      <span>{t('batches.label.batch')}</span>
                    </button>
                    <button
                      className="batch-ez"
                      onClick={async () => {
                        try {
                          await CommonUtils.moengageTrackEvent('Select EZ Sheet On Front Page')
                        } finally {
                          window.location.href = '/batches/ez_spread_sheet'
                        }
                      }}
                      type="button"
                    >
                      <span>{t('batches.upload.ez_sheet')}</span>
                    </button>
                  </>
                )}
                {isShowSmartLoad && (
                  <>
                    <button
                      className="batch-smart"
                      onClick={async () => {
                        try {
                          await CommonUtils.moengageTrackEvent('Select SLP On Front Page')
                        } finally {
                          window.location.href = '/batches/smart_load_planner'
                        }
                      }}
                      type="button"
                    >
                      <span>{t('batches.label.smart_load_planner')}</span>
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
          {frontPageMenu?.what_new?.show && (
            <div className="front-page__more">
              <p className="front-page__title">{t('lcl.what_new')}</p>
              <img
                className="cur-pointer"
                onClick={() => handleChooseService({ service: frontPageMenu?.what_new?.body[0], areaId, companyId, navigate })}
                src={frontPageMenu?.what_new?.body[0].image}
                alt=""
                srcSet=""
              />
              <div className="flex justify-content-between">
                {frontPageMenu?.what_new?.body?.slice(1).map((item, index) => (
                  renderItemMenu({ service: item, index, extraInfos, companyId, navigate })
                ))}
              </div>
            </div>
          )}
          {visible && (
            <div onClick={backToTop} className="back-to-stop cur-pointer flex">
              <img src={BACK_TO_TOP_ICON} alt="" className="mr10" />
              <div>{t('lcl.back_to_top')}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

FrontPage.propTypes = {
  frontPageMenu: PropTypes.shape({
    background_image: PropTypes.string,
    main_services: PropTypes.shape({
      body: PropTypes.array,
      name: PropTypes.string,
      show: PropTypes.bool
    }),
    what_new: PropTypes.shape({
      body: PropTypes.array,
      name: PropTypes.string,
      show: PropTypes.bool
    }),
  }).isRequired
}

export default FrontPage