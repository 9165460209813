import firebaseInit from 'utils/firebaseInit'

import {
  getBookingDetailAction,
  updateBookingTrackingPage,
} from '../common/bookingActionCreators'
import { FIREBASE_SHOPPING_ORDER_UPDATE } from 'constants/bookingConstants'

const checkIsOwnerBooking = (bookingInfos, getState) => bookingInfos.customer_id === getState().currentCustomer.id
  && bookingInfos.id === getState().booking.id

export const startTracking = () => (dispatch, getState) => {
  const bookingPath = `customers/${getState().currentCustomer.id}/available_bookings`
  const csFindingPath = `customers/${getState().currentCustomer.id}/cs_finding_driver_bookings`
  const firebaseBooking = firebaseInit.listen(bookingPath)
  const firebaseCSFinding = firebaseInit.listen(csFindingPath)

  firebaseBooking.on('child_added', (snapshot) => {
    const bookingInfos = snapshot.val()
    if (checkIsOwnerBooking(bookingInfos, getState)) {
      dispatch(updateBookingTrackingPage())
    }
  })

  firebaseBooking.on('child_removed', (snapshot) => {
    const bookingInfos = snapshot.val()
    // driver has accepted the booking
    if (checkIsOwnerBooking(bookingInfos, getState)) {
      dispatch(getBookingDetailAction())
    }
  })

  firebaseCSFinding.on('child_added', (snapshot) => {
    const bookingInfos = snapshot.val()
    if (checkIsOwnerBooking(bookingInfos, getState)) {
      dispatch(getBookingDetailAction())
    }
  })

  firebaseCSFinding.on('child_removed', (snapshot) => {
    const bookingInfos = snapshot.val()
    if (checkIsOwnerBooking(bookingInfos, getState)) {
      dispatch(getBookingDetailAction())
    }
  })
}

export const driverPositionTracking = (booking) => {
  return new Promise((resolve) => {
    const firebaseDriver = firebaseInit.listen(`drivers/${booking.driver_id}`)
    firebaseDriver.once('value').then((snapshot) => {
      resolve(snapshot.val())
    })
  })
}

// export const driverTracking = () => (dispatch, getState) => {
//   const currentBooking = getState().booking
//   return new Promise((resolve) => {
//     const firebaseDriver = firebaseInit.listen(`vehicle_types/${currentBooking.vehicle_type_id}/offline_drivers/${currentBooking.driver_id}`)
//     firebaseDriver.once('value').then((snapshot) => {
//       resolve(dispatch(bookingActionsCreator.bookingUpdateDriverState({
//         value: !snapshot.exists()
//       })))
//     })
//   })
// }

export const startTrackingShoppingOrders = firebaseToken => (dispatch, getState) => {
  const currentBooking = getState().booking

  if (!firebaseToken) {
    console.warn('Missing firebase_token')
  }

  Promise.resolve(
    firebaseInit.load()
  ).then(() => {
    firebaseInit.authenticate(firebaseToken)
  })

  const shoppingOrderRef = firebaseInit.listen(`shopping_orders/${currentBooking.id}`)
  shoppingOrderRef.on('value', (snapshot) => {
    dispatch({
      type: FIREBASE_SHOPPING_ORDER_UPDATE,
      value: snapshot.val()
    })
  })

  return shoppingOrderRef
}

export const stopTrackingShoppingOrders = (shoppingOrderRef) => {
  // TODO: we need to check this ref is valid
  try {
    // After stoped tracking should we clear the current state, we keep it at the moment
    shoppingOrderRef.off()
  } catch (error) {
    console.warn('stopTrackingShoppingOrders failed')
  }
}

export const updateShoppingOrderCustomerItem = (shoppingOrderRef, { newValue, id }) => () => {
  // After stoped tracking should we clear the current state, we keep it at the moment
  const childRef = shoppingOrderRef.child(`customer_order/items/${id}`)

  // we don't dispatch any action here because we already listen on `value` event of firebase, it will trigger there
  childRef
    .update(newValue)
    .catch((err) => {
      console.warn('removeShoppingOrderCustomerItem failed', err)
    })
}

export const removeShoppingOrderCustomerItem = (shoppingOrderRef, { id }) => (dispatch, getState) => {
  const firebaseShoppingOrder = getState().shoppingOrder
  if (!firebaseShoppingOrder) {
    return
  }

  // we don't dispatch any action here because we already listen on `value` event of firebase, it will trigger there
  shoppingOrderRef
    .child(`customer_order/items/${id}`)
    .remove()
    .catch((err) => {
      console.warn('removeShoppingOrderCustomerItem failed', err)
    })
}

export const addShoppingOrderCustomerItem = (shoppingOrderRef, { newValue }) => (dispatch, getState) => {
  const firebaseShoppingOrder = getState().shoppingOrder
  if (!firebaseShoppingOrder) {
    return
  }

  // we don't dispatch any action here because we already listen on `value` event of firebase, it will trigger there
  shoppingOrderRef
    .child('customer_order/items')
    .update({
      [newValue.id]: newValue
    })
    .catch((err) => {
      console.warn('removeShoppingOrderCustomerItem failed', err)
    })
}
